import React from 'react';
import clsx from 'clsx';
// gatsby
import { useStaticQuery, graphql, Link } from 'gatsby';
//styles
import styles from './swg-offer.module.css';
// components
import Layout from 'components/services/components/layout';
import Awards from 'components/about-us/awards';
import Booking from 'components/feedback';
import VideoBlock from './video-block';
import Services from './services/index';
import Blockquote from 'components/cases/components/blockquote';
// meta
import { meta } from 'metaData';

interface Props {
  location: Location;
}

const SwgOffer = ({ location }: Props) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "mafiaLogo.svg" }) {
        publicURL
      }
      kalev: file(relativePath: { eq: "saas/kalev.png" }) {
        childImageSharp {
          fluid(maxWidth: 215, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      patrick: file(relativePath: { eq: "patrick.png" }) {
        childImageSharp {
          fluid(maxWidth: 215, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      eugeneUrsalov: file(relativePath: { eq: "industries/eugene-ursalov.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      rodion: file(relativePath: { eq: "rodion.png" }) {
        childImageSharp {
          fluid(maxWidth: 1220, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout
      title={'Tech Teams \n for The Family'}
      subtitle={
        <span className={styles.subtitle}>
          Get tech support, hire top tech talents with Brocoders ‒ we provide team augmentation
          services and tech support to strengthen your Startup with the required skill sets.
        </span>
      }
      btnText="CONTACT US"
      metaData={{ main: meta.swgOffer }}
      pathname={location.pathname}
      stylesImg={styles.logo}
      logoImg={data.logo.publicURL}
      linkTo="#booking"
      isWithSubNavigation={false}
    >
      <main style={{ overflow: 'hidden' }}>
        <section className={styles.grey}>
          <div className="inner">
            <ul className={styles.benefits}>
              <li className={styles.benefitsItem} key="Startups number">
                <span className={styles.point}>3</span>
                <span className={styles.description}>
                  {'Startups from Startup \n Wise Guys portfolio'}
                </span>
              </li>
              <li className={styles.benefitsItem} key="software engineers number">
                <span className={styles.point}>87</span>
                <span className={styles.description}>{'In-house software \n engineers'}</span>
              </li>
              <li className={styles.benefitsItem} key="Candidates number">
                <span className={styles.point}>10000</span>
                <span className={styles.description}>{'Candidates \n in our database'}</span>
              </li>
              <li className={styles.benefitsItem} key="Clutch rating">
                <span className={styles.point}>5.0</span>
                <span className={styles.description}>{'Clutch rating \n by 28 reviews'}</span>
              </li>
            </ul>
          </div>
        </section>
        <section className="inner">
          <VideoBlock
            image={data.rodion.childImageSharp.fluid}
            source="SaaS_Batch_16"
            title="Hi from SaaS Batch 16, Tallinn!"
            description="My name is Rodion, I’m CTO and Co-founder at Brocoders and Casers. Casers is an educational platform that graduated from Startup Wise Guys Batch 16 in 2020. But today I’d like to tell you a bit about another company of mine."
            author={
              <>
                <strong>Rodion Salnik</strong> <br />
                Co-Founder,{' '}
                <Link to="/" className={styles.red}>
                  Brocoders
                </Link>
              </>
            }
          />
        </section>
        <section className={clsx('inner-container', styles.servicesMargin)}>
          <Services />
        </section>
        <ul className={styles.blockquotelist}>
          <li className={styles.blockquoteItem} key="Adact">
            <Blockquote
              text="“We’ve tested the product and have acquired over two million interactions without experiencing downtime or bug-related issues, so I can confidently say that Brocorders has met our success criteria.”"
              img={data.kalev.childImageSharp.fluid}
              blockquoteClassName={styles.blockquote}
              author={
                <React.Fragment>
                  <strong>Kalev Kärpuk</strong> <br />
                  CEO &amp; Founder,{' '}
                  <a href="https://adact.me/" target="_blank" className={styles.red}>
                    Adact
                  </a>
                </React.Fragment>
              }
            />
          </li>
          <li className={styles.blockquoteItem} key="Truck4goods">
            <Blockquote
              text="“Thanks to Brocoders’ effort, the website managed to hit 100 trial customers, meeting the internal team’s expectations. The team was highly meticulous when it came to details. They were polite, supportive, and highly creative.”"
              img={data.patrick.childImageSharp.fluid}
              blockquoteClassName={styles.blockquote}
              author={
                <React.Fragment>
                  <strong>Patrick Collins</strong> <br />
                  Founder,{' '}
                  <a href="https://prospectlabs.co/" target="_blank" className={styles.red}>
                    Prospect Labs
                  </a>
                </React.Fragment>
              }
            />
          </li>

          <li className={styles.blockquoteItem} key="Condogenie">
            <Blockquote
              text="“I was impressed by Brocoders startup mentality and ability to think long-term, taking a proactive approach in discussing the future of my project. It seems that they take my project personally and aspire to make it succeed. I felt that they were truly interested in the idea and wanted to make the best out of it.”"
              img={data.eugeneUrsalov.childImageSharp.fluid}
              blockquoteClassName={styles.blockquote}
              author={
                <React.Fragment>
                  <strong>Eugene Ursalov </strong> <br />
                  CEO,{' '}
                  <a
                    href="https://casers.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.red}
                  >
                    Casers
                  </a>
                </React.Fragment>
              }
            />
          </li>
        </ul>
        <h2 className={styles.awardsCaption}>Awards</h2>
        <Awards isGrayBackground={false} isWithCaption={false} />
        <div id="booking">
          <Booking
            spesialContact
            title="Get a 5% discount"
            subtitle="Get a 5% discount for Startup Wise Guys members for the first 3 months of collaboration!"
          />
        </div>
      </main>
    </Layout>
  );
};

export default SwgOffer;
