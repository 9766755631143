import React from 'react';
// gatsby
import { Link } from 'gatsby';
import styles from './services.module.css';
import services from '../services';

const Services = () => {
  return (
    <div>
      <h2 className={styles.title}>Services we offer</h2>
      <ul className={styles.serviceList}>
        {services.map(({ title, decription, link }) => (
          <li className={styles.serviceItem}>
            <p className={styles.serviceTitle}>{title}</p>
            <p className={styles.serviceDescription}>{decription}</p>
            <Link to={link.to} className={styles.link}>
              {link.label}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default Services;
