const Routes = require('../../routes').Routes;
export default [
  {
    title: 'Dedicated Tech Team',
    decription: 'Get a complete “two-pizza” dedicated development team',
    link: { label: 'Learn more', to: Routes.SERVICES_DEDICATED_TEAM },
  },
  {
    title: 'Team Augmentation',
    decription:
      'Frontend, Backend developers, QA engineers, Project Managers to strengthen your team',
    link: { label: 'Learn more', to: Routes.SERVICES_TEAM_AUGMENTATION },
  },
  {
    title: 'Tech advising/consulting',
    decription:
      'Architectural issues, performance and scalability problems, big checks for AWS, teach team building and other questions, address them for free!',
    link: { label: 'Contact us', to: '#booking' },
  },
];
