import React, { memo } from 'react';
import VideoModal from 'components/pop-ups/video-modal';
// gatsby
import Img, { FluidObject } from 'gatsby-image';
//styles
import styles from './video-block.module.css';
//hooks
import useModal from 'hooks/useModal';
//icons
import PlayBtn from '../../../../images/career/pink-btn.inline.svg';

interface IVideoBlock {
  source: string;
  image: FluidObject | FluidObject[];
  title?: string;
  description?: string;
  author?: string | React.ReactNode;
}

const { GATSBY_STRAPI_CDN } = process.env;

function VideoBlock({ image, source, title, description, author }: IVideoBlock) {
  const { handleModal } = useModal();

  return (
    <div className={styles.card}>
      <div className={styles.item}>
        <p className={styles.cardTitle}>{title}</p>
        <p className={styles.description}>{description}</p>
        <p className={styles.author}>{author}</p>
      </div>
      <div
        className={styles.thumb}
        onClick={() =>
          handleModal(
            <VideoModal
              source={`${GATSBY_STRAPI_CDN}/video/${source}`}
              preload="auto"
              autoPlay={true}
              controls
              width="100%"
              height="auto"
            />,
            false
          )
        }
      >
        <Img fluid={image} className={styles.img} alt="poster for video" />
        <PlayBtn className={styles.playBtn} />
      </div>
    </div>
  );
}

export default memo(VideoBlock);
